<template>
    <div class="experiment-record-ctn">
        <div class="experiment-record-content">
            <a-tabs v-model="tab" @change="handleTabChange">
                <a-tab-pane :key="1" tab="操作记录">
                    <div class="operation-record-ctn">
                        <div class="operation-search-panel">
                            <div class="search-option">
                                <div class="search-option-label">
                                    操作类型：
                                </div>
                                <div class="search-option-value">
                                    <a-select v-model="OperationType" placeholder="请选择" allowClear>
                                        <a-select-option :key="index"
                                            v-for="(item, index) in [{ label: '新建实验', value: 1 }, { label: '打开实验', value: 2 }, { label: '导出实验', value: 3 }, { label: '审核实验', value: 4 }, { label: '关闭实验', value: 5 }, { label: '回滚实验', value: 6 },{ label: '生成版本', value: 7 } ]"
                                            :value="item.value">
                                            {{ item.label }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </div>
                            <div class="search-option">
                                <div class="search-option-label">
                                    操作时间：
                                </div>
                                <div class="search-option-value">
                                    <a-range-picker value-format="YYYY-MM-DD" v-model="operationRangeTime" />
                                </div>
                            </div>
                            <div class="search-btn">
                                <a-button type="primary" class="search-btn" @click="handleOperationSearch"> 查询
                                </a-button>
                            </div>
                        </div>
                        <div class="operation-content">
                            <a-table rowKey="experimentOperationRecordId" :columns="operationRecordCloumns"
                                :data-source="operationRecordList" :pagination="false" :defaultExpandAllRows="true"
                                :loading="loading" :expandedRowKeys.sync="expandedRowKeys"
                                :rowClassName="getRowClassName">
                                <!--  <template  slot="expandIcon" slot-scope="props">
                                    <div v-if="props.operationType !== 5" style="display: none;" >
                                        {{props}}
                                    </div>
                                    <div v-else>
                                        <a-icon type="plus-square" />
                                        {{props}}
                                    </div>
                                </template> -->
                                <template v-if="record.operationType === 5" slot="expandedRowRender"
                                    slot-scope="record, index">
                                    <div class="expand-row">
                                        <a-table size="small" :pagination="false" :bordered="true"
                                            :columns="operationRecordDetailColumns"
                                            :data-source="record.operationModifyTraces" rowKey="moduleName">
                                            <template slot="remark" slot-scope="text,expandRecord, expandIndex">
                                                <div>
                                                    {{ expandRecord.remark }}
                                                </div>
                                            </template>
                                            <template slot="oldValue" slot-scope="text,expandRecord, expandIndex">
                                                <a-button v-if="expandRecord.oldValue && expandRecord.isJsonFormat"
                                                    type="link" @click="handleCheckValueClick(text)">
                                                    查看详情
                                                </a-button>
                                                <div v-else>
                                                    {{ expandRecord.oldValue }}
                                                </div>
                                            </template>
                                            <template slot="newValue" slot-scope="text,expandRecord, expandIndex">
                                                <a-button v-if="expandRecord.newValue && expandRecord.isJsonFormat"
                                                    type="link" @click="handleCheckValueClick(text)">
                                                    查看详情
                                                </a-button>
                                                <div v-else>
                                                    {{ expandRecord.newValue }}
                                                </div>
                                            </template>
                                        </a-table>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                        <div class="default-pagination">
                            <a-pagination size="small" hideOnSinglePage @change="handleOperationPageChange"
                                v-model="operationPage" :pageSize="10" :total="operationTotal" show-less-items />
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="版本记录">
                    <div class="version-record-ctn">
                        <a-table rowKey="experimentVersionId" size="small" :bordered="false"
                            :columns="versionRecordColumns" :scroll="{ y: verisionTableHeight }" :pagination="false"
                            :data-source="versionList" :loading="loading">
                            <template slot="operation" slot-scope="text, record, index">
                                <div class="operation-btns">
                                    <a-button type="link" v-if="record.rollbackPermission"
                                        @click="handleRollBack(record)">
                                        回滚
                                    </a-button>
                                    <a-tooltip v-else title="">
                                        <a-button type="link" disabled>
                                            回滚
                                        </a-button>
                                    </a-tooltip>
                                    <a-button type="link" @click="handleCheckDetail(record)">
                                        查看详情
                                    </a-button>
                                </div>
                            </template>
                        </a-table>
                    </div>
                    <div class="default-pagination">
                        <a-pagination size="small" hideOnSinglePage @change="handlePageChange" v-model="versionPage"
                            :pageSize="10" :total="versionTotal" show-less-items />
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
        <CheckValueModal :data="checkValue" :visible.sync="checkValueModalVisible"></CheckValueModal>
    </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import { message, Modal } from 'ant-design-vue';
import { getExperimentOperationRecord, getExperimentVersionRecord, experimentRollBackVerion } from '../../api/experiment';
import CheckValueModal from './components/checkValueModal.vue';
// 操作记录table列
const operationRecordCloumns = [
    {
        title: '版本',
        dataIndex: 'version'
    },
    {
        title: '操作类型',
        dataIndex: 'operationTypeNmae'
    },
    {
        title: '操作时间',
        dataIndex: 'createdTime'
    },
    {
        title: '操作人',
        dataIndex: 'createdUserName'
    },
    {
        title: 'IP地址',
        dataIndex: 'ip'
    },
    {
        title: '描述',
        dataIndex: 'description',
        width: `15%`
    }
]
// 操作记录展开详情table列
const operationRecordDetailColumns = [
    {
        title: '模块名称',
        dataIndex: 'moduleName',
        width: '25%'
    },
    {
        title: '字段名称',
        dataIndex: 'fieldName',
        width: '25%',
        scopedSlots: { customRender: "fieldName" }
    },
    {
        title: '旧值',
        dataIndex: 'oldValue',
        width: '19%',
        scopedSlots: { customRender: "oldValue" }
    },
    {
        title: '新值',
        dataIndex: 'newValue',
        width: '19%',
        scopedSlots: { customRender: "newValue" }
    },
    {
        title: '备注',
        dataIndex: 'remark',
        width: '12%',
        scopedSlots: { customRender: "remark" }
    }
]
// 版本记录table列
const versionRecordColumns = [
    {
        title: '实验名称',
        dataIndex: 'title'
    },
    {
        title: '保存时间',
        dataIndex: 'createdTime'
    },
    {
        title: '版本',
        dataIndex: 'version'
    },
    {
        title: '备注',
        dataIndex: 'remark'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        width: '220px',
        fixed: 'right',
        scopedSlots: { customRender: "operation" }
    }
]
// 版本记录表高度
const verisionTableHeight = document.documentElement.clientHeight - 300;
export default {
    props: [],
    setup(props, context) {
        // 活跃的标签，1： 操作记录。2：版本记录
        const tab = ref(context.root.$route.query.tab ? parseInt(context.root.$route.query.tab.toString()) : 1);
        // 实验的id
        const id = ref(context.root.$route.query.id ? context.root.$route.query.id : undefined);
        // 标签改变事件
        const handleTabChange = (activeTab) => {
            context.root.$router.replace({
                name: "experimentRecord",
                query: {
                    tab: activeTab,
                    id: id.value
                }
            });
            if (tab.value === 1) {
                OperationType.value = undefined;
                operationRangeTime.value = [];
                operationPage.value = 1;
                handleOperationPageChange();
            }
            else {
                versionPage.value = 1;
                handlePageChange();
            }
        };
        /*******************************       操作记录 start                  **********************/
        // 操作记录数据
        const operationRecordList = ref([]);
        // 操作类型
        const OperationType = ref(undefined);
        // 操作时间
        const operationRangeTime = ref([]);
        // 操作记录 分页
        const operationPage = ref(1);
        // 操作记录 总数
        const operationTotal = ref(0);
        // 操作记录 分页
        const handleOperationPageChange = async (page, pageSize) => {
            loading.value = true;
            const res = await getExperimentOperationRecord({
                ExperimentId: id.value,
                pageNo: operationPage.value,
                PageSize: 10,
                OperationType: OperationType.value ? OperationType.value : "",
                SearchBeginTime: operationRangeTime.value.length === 2 ? operationRangeTime.value[0] : "",
                SearchEndTime: operationRangeTime.value.length === 2 ? operationRangeTime.value[1] : ""
            });
            if (res.code === 200) {
                operationRecordList.value = res.data.rows;
                operationTotal.value = res.data.totalRows;
            }
            loading.value = false;
        };
        // 操作记录查询
        const handleOperationSearch = () => {
            operationPage.value = 1;
            handleOperationPageChange();
        };
        // 展开的行
        const expandedRowKeys = ref([]);
        /*******************************       操作记录 end                    **********************/
        /*******************************       版本记录 start                  **********************/
        // 版本记录数据
        const versionList = ref([]);
        // 版本记录 分页
        const versionPage = ref(1);
        // 版本记录 总数
        const versionTotal = ref(0);
        // 版本记录分页
        const handlePageChange = async (page, pageSize) => {
            loading.value = true;
            const res = await getExperimentVersionRecord({
                ExperimentId: id.value,
                pageNo: versionPage.value,
                PageSize: 10
            });
            if (res.code === 200) {
                versionList.value = res.data.rows;
                versionTotal.value = res.data.totalRows;
            }
            loading.value = false;
            return;
        };
        // 回滚记录
        const handleRollBack = (record) => {
            Modal.confirm({
                title: "实验回滚",
                content: "是否确认将当前实验回滚至该版本？ ",
                onOk: async () => {
                    const res = await experimentRollBackVerion({
                        experimentVersionId: record.experimentVersionId
                    });
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        handlePageChange();
                    }
                },
                onCancel: () => {
                    return;
                }
            });
        };
        const handleCheckDetail = (record) => {
            const href = context.root.$router.resolve({ name: "experiment", query: { mode: "preview", experimentVersionId: record.experimentVersionId } });
            window.open(href.href, "_blank");
        };
        /*******************************       版本记录 end                  **********************/
        // 加载变量
        const loading = ref(false);
        onMounted(async () => {
            if (id.value) {
                loading.value = true;
                if (tab.value === 1) {
                    const res = await getExperimentOperationRecord({
                        ExperimentId: id.value,
                        pageNo: operationPage.value,
                        PageSize: 10,
                        OperationType: OperationType.value ? OperationType.value : "",
                        SearchBeginTime: operationRangeTime.value.length === 2 ? operationRangeTime.value[0] : "",
                        SearchEndTime: operationRangeTime.value.length === 2 ? operationRangeTime.value[1] : ""
                    });
                    if (res.code === 200) {
                        operationRecordList.value = res.data.rows;
                        operationTotal.value = res.data.totalRows;
                        /* expandedRowKeys.value = operationRecordList.value.map((Element) => {
                            return Element.experimentOperationRecordId;
                        }) */
                    }
                }
                else {
                    const res = await getExperimentVersionRecord({
                        ExperimentId: id.value,
                        pageNo: versionPage.value,
                        PageSize: 10
                    });
                    if (res.code === 200) {
                        versionList.value = res.data.rows;
                        versionTotal.value = res.data.totalRows;
                    }
                }
                loading.value = false;
            }
        });
        const current = ref(1);
        const test = (record) => {
            console.log(record);
        };
        /**************************              查看详情  start            *****************************/
        // 查看详情对话框
        const checkValueModalVisible = ref(false);
        // 选中的值
        const checkValue = ref("{  }");
        // 选中点击
        const handleCheckValueClick = (text) => {
            checkValue.value = text;
            //console.log(text)
            checkValueModalVisible.value = true;
        }
        /**************************              查看详情  end              *****************************/

        const getRowClassName = (record, index) => {
            if (record.operationType === 5) {
                return 'expandable-row'
            }
            else {
                return 'unexpandable-row'
            }
        }
        return {
            tab,
            handleTabChange,
            operationRecordCloumns,
            expandedRowKeys,
            operationRecordDetailColumns,
            operationRecordList,
            versionRecordColumns,
            versionList,
            verisionTableHeight,
            current,
            handlePageChange,
            operationPage,
            operationTotal,
            handleOperationPageChange,
            loading,
            OperationType,
            operationRangeTime,
            handleOperationSearch,
            versionPage,
            versionTotal,
            handleRollBack,
            handleCheckDetail,
            test,
            checkValueModalVisible,
            handleCheckValueClick,
            checkValue,
            getRowClassName
        };
    },
    components: { CheckValueModal }
}
</script>

<style lang="less" scoped>
.experiment-record-ctn {
    padding: 16px;

    .experiment-record-content {
        background: #FFFFFF;
        text-align: left;
        min-height: calc(100vh - 120px);



        /deep/ .ant-tabs-nav .ant-tabs-tab-active {
            color: @srims-primary-color;
        }

        /deep/ .ant-tabs-ink-bar {
            background-color: @srims-primary-color;
        }

        .operation-record-ctn {
            padding: 31px 16px 16px 16px;

            .operation-search-panel {
                display: flex;

                .search-option {
                    display: flex;
                    align-items: center;

                    .search-option-label {
                        white-space: nowrap;
                    }

                    .search-option-value {
                        display: flex;
                        align-items: center;
                        min-width: 220px;

                        .ant-select {
                            width: 100%;
                        }

                        .date-gap {
                            width: 12px;
                            height: 1px;
                            margin: 0px 7px;
                            background: #3D3D3D;
                        }
                    }
                }

                .search-btn {
                    margin-left: 40px;

                    .ant-btn {
                        background: @srims-primary-color;
                    }
                }

                .search-option+.search-option {
                    margin-left: 40px;
                }
            }

            .operation-content {
                margin-top: 24px;
                min-height: calc(100vh - 350px);

                /deep/ .ant-table-thead>tr>th,
                .ant-table-tbody>tr>td {
                    padding: 5px;
                    text-align: left;
                    background: #F0F5FF;
                }

                /deep/ tr.ant-table-expanded-row {
                    background: #ffffff;

                    td:nth-child(1) {
                        //display: none;
                    }

                    td:nth-child(2) {
                        // border-bottom: none;
                        padding: 16px;
                    }
                }

                .expand-row {
                    width: 103%;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #EAEEF3;
                    transform: translateX(-3.5%);

                    /deep/ .ant-table-thead>tr>th,
                    .ant-table-tbody>tr>td {
                        background: #F7F8FA;
                        text-align: left;
                    }

                    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
                        margin: 0px;
                    }
                }
            }
        }

        .version-record-ctn {
            padding: 0px 16px;
            min-height: calc(100vh - 250px);

            /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
                margin: 0px;
            }

            /deep/ .ant-table-thead {
                background: #F0F5FF;
            }
        }

        .default-pagination {
            text-align: center;
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
}
</style>

<style lang="less" scoped>
/deep/ .unexpandable-row > .ant-table-row-expand-icon-cell > .ant-table-row-expand-icon {
    display: none;
}
</style>