<template>
    <a-modal :visible.sync="visible" title="查看详情" :width="1000" @cancel="handleCancel" :footer="null">
        <k-form-component-panel v-if="visible" :class="{ 'no-toolbars-top': false }" :data="panelData" :selectItem="{}" :noModel="noModel"
            :hideModel="true" :startType="startType" ref="KFCPP" :mode="'preview'" />
    </a-modal>
</template>

<script>
import { computed,ref } from 'vue-demi';
import kFormComponentPanel from '../../../components/k-form-design/packages/KFormDesign/module/formComponentPanel';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        data: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        const eData = ref({
            "list": [],
            "config": {
                "layout": "vertical",
                "labelCol": {
                    "xs": 4,
                    "sm": 4,
                    "md": 4,
                    "lg": 4,
                    "xl": 4,
                    "xxl": 4
                },
                "labelWidth": 100,
                "labelLayout": "flex",
                "wrapperCol": {
                    "xs": 18,
                    "sm": 18,
                    "md": 18,
                    "lg": 18,
                    "xl": 18,
                    "xxl": 18
                },
                "hideRequiredMark": false,
                "customStyle": ""
            }
        });
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const startType = ref("");
        const KFCPP = ref(null);
        const noModel = ref([
            "button",
            "divider",
            "card",
            "grid",
            "tabs",
            "table",
            "alert",
            "text",
            "html"
        ]);
        const panelData =  computed(() => {
            const newEData = JSON.parse(JSON.stringify(eData.value));
            newEData.list = [ JSON.parse(props.data) ];
            return newEData;
        })
        return {
            handleCancel,
            startType,
            KFCPP,
            noModel,
            panelData
        }
    },
    components: { kFormComponentPanel }
}
</script>

<style lang="less" scoped>
    /deep/ .label-container {
        cursor: auto;
    }
    /deep/ .comment-icon {
        display: none;
    }
</style>